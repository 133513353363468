@use "../../public/styles/variables.scss";

.try .content {
  background-color: variables.$neutral_01;
  background-color: #536ced;
  padding: 80px 40px;
  text-align: center;
  color: variables.$ui_01;
  border-radius: 8px;

  h2 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 768px) {
  body > main .try .content {
    padding: 32px 16px;

    h2, p {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .try .content{
    text-align: left;

    .button {
      width: 100%;
      text-align: center;
    }
  }
}