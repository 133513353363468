.try .content {
  text-align: center;
  color: #fff;
  background-color: #536ced;
  border-radius: 8px;
  padding: 80px 40px;
}

.try .content h2 {
  margin-bottom: 12px;
}

.try .content p {
  margin-bottom: 32px;
}

@media only screen and (width <= 768px) {
  body > main .try .content {
    padding: 32px 16px;
  }

  body > main .try .content h2, body > main .try .content p {
    text-align: center;
  }
}

@media only screen and (width <= 480px) {
  .try .content {
    text-align: left;
  }

  .try .content .button {
    text-align: center;
    width: 100%;
  }
}
/*# sourceMappingURL=try_platform.268b9b2d.css.map */
